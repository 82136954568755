@import '../../sass/main.scss';

h1, h2, h3 {
    font-family: $font-monda;
}

.gradient-custom {
    /* fallback for old browsers */
    background: $color121;
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, $color121, rgba(37, 117, 252, 1));
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, $color121, rgba(37, 117, 252, 1));
    min-height: 100vh;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      font-size: 32px;
      letter-spacing: 3px;


      // img {
      //     @include hazo-logo;
      //     margin-right: 10px;
      // }
    }

    .balloon-container {
      margin: 30px auto;
      margin-bottom: 10px;
      display: flex;
      width: 250px;
      position: relative;
      justify-content: center;

      .hazo-heroi {
        @include img-Hazo;
        margin-top: 80px;
        background: $color121;
      }

      .img-hazo {
        @include img-Hazo;
        margin-top: 80px;
        padding-left: 15px;
      }

      .text-balloon {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0px;
        left: 30px;
        height: 35px;
        width: 170px;
        z-index: 1;


        img {
          width: 100%;
          object-fit: contain;
          position: absolute;
          padding-bottom: 10px;
        }

        p {
          margin-left: 15px;
          line-height: normal;
        }
      }

      & > div {
          position: absolute;
          top: 5px;
          z-index: 2;
          margin: 0 auto;
          margin-bottom: 20px;

      .pix-line {
          margin-bottom: 0;
      }

      .opinion-line {
          font-size: 13px;
      }
      }
    }

    .text-container {

      h3 {
        text-align: center;
        font-size: 30px;
        font-family: $font-roboto;
        letter-spacing: 1.5px;
        margin: 0;
      }

      h4 {
        text-align: center;
        font-size: 20px;
        margin-bottom: 30px;
      }
    }

    .bottom-card {
      .btn-cadastro {
        margin-top: 25px;
        margin-bottom: 10px;
        font-size: 14px;
      }

      & > p a {
        font-size: 12px;
      }

      & > span {
        font-size: 15px;
      }
    }


    .btn-send {
      background: $color121;
      color: $white;

      &:hover, &:focus {
        background: $color121-dark;
        color: $white;
      }
    }

    .fw-10 {
      font-size: 10px;
    }

}

