@import '../../sass/main.scss';

.card-info {
    border-radius: 10px;
    background: $gray-light;
}

.a-home {
    font-weight: 500;
}
