@import "../../sass/main.scss";

.compEmailValidation {
  .buttonSendEmail {
    background-color: $color121;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0;
    cursor: pointer;
  }

  .buttonSendEmail:disabled {
    background-color: $gray-default;
    color: #fff;
    cursor: not-allowed;
  }

  .buttonSendEmail:hover, .buttonSendEmail:focus {
    background-color: $color121-hover;
    color: #fff;
  }

  .error {
    font-size: 14px;
    color: $danger-color;
  }

  strong {
    text-transform: uppercase;
    color: #3c3b3b;
  }
}
