@import "../../sass/main.scss";

.cards {
  font-weight: 300;
  font-size: 16px;

  h4 {
    font-size: 18px;
    padding-bottom: 15px;
  }

  .research {
    background-color: $color121-hover;
    border-radius: 10px;
    min-width: 250px;
    min-height: 200px;
    color: #fff;
    font-size: 16px;
    @include d-flex(center, center, column);

    h5 {
      font-size: 16px;
      padding-bottom: 10px;
    }

    p {
      margin: 0;
      padding-bottom: 10px;
    }

    button {
      background-color: rgba(255, 255, 255, 0);
      border: none;
      color: #fff;
      font-weight: 500;
      padding-top: 10px;
      text-decoration: underline;
    }
  }

  .img-container {
    position: absolute;
    bottom: 10px;
    right: 10px;

    img {
      width: 100px;
      object-fit: contain;
    }
  }
}
