$color121: #609ef1;
$color121-hover: #2165AF;
$color121-dark: #287be1;
$black: #000;
$white: #fff;
$white-opacity: #fffffff0;
$sucess-color: #28a745;
$danger-color: #b72025;
$gray-default: #b1b1b1;
$gray-dark: #4d4d4d;
$gray-medium: #7b7b7b;
$gray-light: #f1f1f1;
