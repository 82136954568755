@import "../../sass/main.scss";

.compDashboard {
  .buttonSignOut,
  .buttonSignOut:hover {
    background-color: $gray-default;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin: 0px 0px 15px 0px;
  }

  .errorMessage {
    color: rgb(156, 1, 1);
    font-size: 1rem;
    text-align: center;
  }

  .strongWithPointer {
    cursor: pointer;
  }
}
