@import '../../sass/main.scss';

h1, h2, h3 {
    font-family: $font-monda;
}

.gradient-custom {
    /* fallback for old browsers */
    background: $color121;
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, $color121, rgba(37, 117, 252, 1));
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, $color121, rgba(37, 117, 252, 1));


    .img-heroi {
        @include img-Hazo;
        background: $color121;
        padding: 0;
    }

    input {
        font-size: 16px;
    }

    .forgot-password {
        display: flex;
        justify-content: flex-end;
        margin: 10px;
        color: $gray-dark;
    }

    .btn-send {
        background: $color121;
        color: $white;

        &:hover, &:focus {
            background: $color121-dark;
            color: $white;
        }
    }

    svg {
        height: 40px;
        width: 40px;
        padding: 5px;
    }

    .registration-div {
        p {
            font-size: 15px;
        }
    }

    .eye {
        position: relative;

        & > input {
            padding-right: 3rem;
        }

        .eye-img {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 2.5rem;
            border: none;
            border-bottom-right-radius: 0.4rem;
            border-top-right-radius: 0.4rem;
            background: $color121;

            & > img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                padding: 0.5rem;
            }
        }
    }

    .error {
        font-size: 14px;
        color: $danger-color;
    }


}



