@import '../../sass/main.scss';


.wallet {
  margin: 10px auto;
  margin-bottom: 20px;
  max-height: 150px;

  .money {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    h2, h4{
      font-size: 16px;
      margin: 0;
    }

    h3{
      font-size: 22px;
      font-weight: 700;
      padding: 5px 0;
    }

    h5 {
      font-size: 14px;
    }
  }

  .btn-container {
    @include d-flex(center, center, column);
    .btn-resgate {
      background-color: $gray-default;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      margin-top: 5px;
    }

    .btn-resgate.enabled {
      background-color: $color121;
    }

    .img-heroi {
      margin: 0;
      min-height: 40px;
      object-fit: contain;
      width: 40px;
    }

    span {
      font-size: 11px;
      padding: 5px;
    }
  }
}

