@import '../../sass/main.scss';

h1, h2, h3 {
    font-family: $font-monda;
}

.gradient-custom {
    /* fallback for old browsers */
    background: $color121;
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, $color121, rgba(37, 117, 252, 1));
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, $color121, rgba(37, 117, 252, 1));


    .img-heroi {
        @include img-Hazo;
        background: $color121;
    }

    input {
        font-size: 16px;
    }

    .info-register {
        font-size: 10px;
        text-align: center;

        & > span {
            display: flex;
            justify-content: center;
        }
    }

    .btn-send {
        background: $color121;
        color: $white;

        &:hover, &:focus {
            background: $color121-dark;
            color: $white;
        }
    }

    svg {
        height: 40px;
        width: 40px;
        padding: 5px;
    }

    .registration-div {
        p {
            font-size: 15px;
        }
    }

    select {
        font-size: 14px;
        padding: 10px;
    }

    .select-component {
        text-align: start !important;

        & > div:first-of-type {
            height: 43px;
            display: flex;
            flex-wrap: nowrap;

            & > div:last-child {
                & > span {
                    display: none;
                }

                & > div {
                    svg {
                        color: #000;
                        height: 30px;
                        width: 30px;
                    }
                }

            }

        }
    }



}



