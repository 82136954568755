@import '../../sass/main.scss';

.container {
  .btn-back {
    position: absolute;
    margin-right: 2px;
    margin-top: 2px;
    color: $color121;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
  }

  & .research {
    h3 {
      font-size: 20px;
    }
  }

  .content {
    .p-observation {
      font-size: 14px;
    }

    .img-content {
      margin-top: 20px;
      max-width: 180px;
      max-height: 180px;
    }

    .card-title {
      padding: 0 5px;
    }

    .radio-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px;

      & > label {
          display: flex;
          align-items: center;
      }

      svg {
          height: 32px;
          width: 32px;
          margin-left: 5px;
          margin-right: 3px;
      }
    }

    .card-body-wallet {
      margin: 0 auto;
      max-width: 500px;
      width: 100%;

     .btn-div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
      }

      p {
        width: 100%;
        text-align: justify;
      }

      .modal-container {
          background: rgba(81, 81, 81, 0.511);
          position: fixed;
          display: flex;
          align-items: center;
      }
    }
  }

  @media (max-width: 470px) {
    .content {
    .radio-div {
      flex-direction: column;
      align-items: center;
      margin: 20px;

        svg {
          margin-left: 15px;
          margin-right: 5px;
        }
      }
    }
  }
}

