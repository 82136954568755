@import "../../../sass/main.scss";

.comp-donation {
  .label-area {
    width: 100%;
    margin-top: 20px;
  }

  .error {
    font-weight: 600;
    letter-spacing: 0.7px;
    padding-bottom: 10px;

  }

  .btn-resgate {
    width: 100%;
    background: $color121;
    font-weight: 700;
    color: aliceblue;
    letter-spacing: 0.8px;
    margin-top: 10px;

    &:hover, &:focus {
      background: $color121-dark;
      border-color: $color121-dark;
      color: #fff;
    }
  }
}
