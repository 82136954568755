@import '../../../sass/main.scss';

.content-info {
  margin: 15px 0;
  padding: 0 10px;

  p > b {
    text-transform: uppercase;
    font-weight: 600;
  }

  span {
    display: block;
    font-weight: 500;
    word-spacing: 1.5px;
  }
}

.footer {
  .btn-confirmar {
    background: $color121;
    color: #fff;

    &:hover, &:focus {
      background: $color121-dark;
      border-color: $color121-dark;
      color: #fff;
    }
  }
}
