@mixin d-flex($a: center, $j: center, $d: row) {
  align-items: $a;
  display: flex;
  flex-direction: $d;
  justify-content: $j;
}

@mixin text($w, $s, $t) {
  font: $w $s $t;
}

@mixin img-Hazo {
  width: 130px;
  margin: 10px;
  object-fit: contain;
}

@mixin hazo-logo {
  margin: 0;
  height: 28px;
  object-fit: contain;
}

@mixin resetInput {
  border: 0;
  box-shadow: 0;
  outline: 0;
}
