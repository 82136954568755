.compSurvey {
  height: 100%;
  width: 100%;
  position: fixed;


  iframe {
    height: 100%;
    width: 100%;
  }
}
